(function() { 'use strict';
  // Const
  // -----
  window.SMALL_MOBILE_WIDTH = 480;
  window.MOBILE_WIDTH = 700;
  window.LANDSCAPE_MOBILE_WIDTH = 800;
  window.PORTRAIT_TABLET_WIDTH = 768;
  window.TABLET_WIDTH = 1020;

  // Selectors
  // ---------
  window.$WINDOW = $(window);
  window.$DOCUMENT = $(document);
  window.$HTML = $(document.documentElement);
  window.$BODY = $(document.body);


  // Helpers
  // -------
  window.IS_MOBILE = navigator.userAgent.match(/(iPad)|(iPhone)|(iPod)|(android)|(webOS)|(Windows Phone)/i) != null;
  window.IS_DESKTOP = !IS_MOBILE;
  window.IS_TOUCH_DEVICE = ('ontouchend' in document || !!navigator.msMaxTouchPoints || !!navigator.maxTouchPoints);

  $WINDOW.on('resize', function() {
    window.WINDOW_WIDTH = window.innerWidth || $WINDOW.width();
    window.WINDOW_HEIGHT = $WINDOW.height();
  });

  window.IS_DESKTOP_WIDTH = function() {
    return ( WINDOW_WIDTH >= TABLET_WIDTH );
  };
  window.IS_TABLET_WIDTH = function() {
    return ( WINDOW_WIDTH >= PORTRAIT_TABLET_WIDTH && WINDOW_WIDTH < TABLET_WIDTH );
  };
  window.IS_MOBILE_WIDTH = function() {
    return WINDOW_WIDTH <= MOBILE_WIDTH;
  };
  window.IS_LANDSCAPE_MOBILE_WIDTH = function() {
    return WINDOW_WIDTH <= LANDSCAPE_MOBILE_WIDTH;
  };
  window.IS_SMALL_MOBILE_WIDTH = function() {
    return WINDOW_WIDTH <= SMALL_MOBILE_WIDTH;
  };


  // Masked input
  // ------------
  $('input[type="date"]').attr('type', 'text');

  // Phone
  $('input[type="tel"]').inputmask('+7 (999) 999-99-99', {
    placeholder: "+7 (___) ___-__-__"
  });


  // Hover elements
  // --------------
  $WINDOW.on('resize', function() {
    var $menus = $('.js-menu'),
        $with_popup = $('.js-with-popup');

    $menus.off('click.js-menu');
    $with_popup.off('click.js-with-popup');

    if( IS_MOBILE || !window.IS_DESKTOP_WIDTH() ) {
      $menus.on('click.js-menu', 'a, span, .js-menu-dropmarker', function(e) {
        e.preventDefault();

        var $elem = $(this),
            $parent = $(this).closest('li'),

            isWithDropdown = $parent.hasClass('with-dropdown'),
            isOnHover = $parent.hasClass('is-hovered');

        $parent.siblings('.with-dropdown').removeClass('is-hovered').children('.dropdownmenu-container').slideUp(350);

        if( isWithDropdown ) {
          if( isOnHover ) {
            if( e.target.nodeName.toUpperCase() === 'A' )
              location.href = $elem.prop('href');
            else
              $parent.removeClass('is-hovered').children('.dropdownmenu-container').slideUp(350);
          } else {
            $parent.addClass('is-hovered').children('.dropdownmenu-container').slideDown(350);
          }
        } else {
          if( e.target.nodeName.toUpperCase() === 'A' )
            location.href = $elem.prop('href');
        }
      });

      $with_popup.on('click.js-with-popup', function(e) {
        e.preventDefault();

        var $elem = $(this);

        if( $elem.hasClass('is-hovered') ) {
            $elem.removeClass('is-hovered').children('.js-popup').slideUp(350);
        } else {
          var $hoverSiblings = $elem.siblings('.is-hovered');

          if( $hoverSiblings.length > 0 ) {
            $hoverSiblings.removeClass('is-hovered').children('.js-popup').slideUp(350, function() {
              $elem.addClass('is-hovered').children('.js-popup').slideDown(350);
            });
          } else {
            $elem.addClass('is-hovered').children('.js-popup').slideDown(350);
          }
        }
      });
    }
  });


  // Scroll to
  // ---------
  $DOCUMENT.on('click.js-scroll-to', '.js-scroll-to', function(e) {
    e.preventDefault();

    var $lnk = $(this),
        $elemToScroll = $($lnk.attr('href').substr($lnk.attr('href').lastIndexOf('#'))),
        speed = $lnk.data('scrollSpeed') || 150,
        offset = $lnk.data('scrollOffset') || 0;

    $WINDOW.scrollTo($elemToScroll, {duration: speed, offset: offset});
  });


  // Header menu button
  // ------------------
  $('.header-menu-checkbox')
    .on('change', function() {
      if( this.checked ) {
        $BODY.addClass('posr ofh');
      } else {
        $BODY.removeClass('posr ofh');
      }
    })
    .prop('checked', false)
    .prop('disabled', false)
    .trigger('change');


  // FancyBox
  // --------
  $.extend(true, $.fancybox.defaults, {
    loop: true,
    margin: 0,

    infobar: false,
    buttons: [
      'close',
      false,
      false,
      false
    ],

    smallBtn: false,

    protect : true,

    focusElement: '.js-autofocus-inp'
  });

  $('[data-fancybox]').fancybox();


  // Slick sliders
  // -------------
  // Main rotator
  $('.main-rotator-container').slick({
    autoplay: true,
    autoplaySpeed: 6000,
    customPaging: function() { return $('<div />'); },
    draggable: false,

    centerMode: true,
    centerPadding: '0px',
    fade: true,
    arrows: false,
    dots: true,
    slide: '.main-rotator-slide-container',
    appendDots: '#main-rotator-dots'
  });


  // Triggers
  // --------
  $WINDOW.trigger('resize');

})();
